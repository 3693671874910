export default [
  {
    header: 'Accessibility',
  },
  // {
  //   title: 'Chat',
  //   route: 'apps-chats',
  //   icon: 'MessageCircleIcon',
  //   resource: 'Support',
  //   action: 'Support',
  // },
  {
    title: 'Tickets',
    icon: 'MessageSquareIcon',
    children: [
      {
        title: 'List',
        route: 'apps-allTickets',
      },
    ],
  },
  {
    title: 'Users',
    icon: 'UserIcon',
    children: [
      {
        title: 'List',
        route: 'apps-users-list',
      },
    ],
  },
  {
    title:'Candles',
    icon:'ActivityIcon',
    children:[
      {
        title: 'List',
        route: 'apps-candles-list',
      },
    ],
  },
  {
    title:'Trades',
    icon:'DollarSignIcon',
    children:[
      {
        title: 'List',
        route: 'apps-trades-list',
      },
    ],
  },
  {
    title:'Wallets',
    icon:'CreditCardIcon',
    children:[
      {
        title: 'List',
        route: 'apps-wallets-list',
      },
    ],
  },
  // {
  //   title: 'orders',
  //   icon: 'TrelloIcon',
  //   children: [
  //     {
  //       title: 'List',
  //       route: 'apps-orders-list',
  //       resource: 'Accountent',
  //       action: 'Accountent',
  //     },
  //   ],
  // },
  // {
  //   title: 'Financial',
  //   icon: 'DollarSignIcon',
  //   children: [
  //     {
  //       title: 'Payments list',
  //       route: 'apps-financial-payments-list',
  //       resource: 'Accountent',
  //       action: 'Accountent',
  //     },
  //   ],
  // },
  // {
  //   title: 'Blogs',
  //   icon: 'BookOpenIcon',
  //   // resource: 'Blogger',
  //   // action: 'Blogger',
  //   children: [
  //     {
  //       title: 'List',
  //       route: 'pages-blog-list',
  //       resource: 'Blogger',
  //       action: 'Blogger',
  //     },
  //     {
  //       title: 'Add',
  //       route: 'pages-blog-create',
  //       resource: 'Blogger',
  //       action: 'Blogger',
  //     },
  //     {
  //       title: 'Categories',
  //       route: 'pages-blog-categories-list',
  //       resource: 'Blogger',
  //       action: 'Blogger',
  //     },
  //     {
  //       title: 'Comments',
  //       route: 'pages-blog-comments',
  //       resource: 'Blogger',
  //       action: 'Blogger',
  //     },
  //   ],
  // },
]
